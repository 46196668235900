import { useRef } from 'react';
import { Preset } from 'types/proto/retriver-struct_pb';
import { PresetView } from './PresetView';
import { useFetchPresets } from 'hooks/useFetchPresets';

export function All() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchPresets({
    showHidden: true,
    lastItemRef: lastItemRef,
  });

  return (
    <>
      {list.map((p: Preset) => {
        return (
          <PresetView
            key={p.id}
            preset={p}
            onDelete={(id: string) => {
              setList(list.filter((p) => p.id !== id));
            }}
          />
        );
      })}
      {hasMore && (
        <div
          key="presetListLastItemRef"
          ref={lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1rem',
            minHeight: '100px',
          }}
        ></div>
      )}
    </>
  );
}
