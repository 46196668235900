import { Divider, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { SubTitle } from 'components/PageTitles';
import React, { useEffect, useState } from 'react';
import { User } from 'types/user';
import componentStyle from 'styles/common.module.css';
import { Grapeseed } from 'api/grapeseed';
import { UserView } from './components/UserView';
import { UserListView } from './components/UserListView';

export function Search() {
  const [form] = useForm();
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const [user, setUser] = useState<User>();

  const onSearch = async (keyword: string) => {
    const resp = await Grapeseed.GET(`/api/users`, {
      queryParams: new Map<string, string>([['keyword', keyword]]),
    });
    if (resp) {
      setSearchResult(resp);
    }
  };

  useEffect(() => {
    if (searchResult.length === 1) {
      setUser(searchResult[0]);
    }
  }, [searchResult]);

  return (
    <React.Fragment>
      <SubTitle>Manage user accounts</SubTitle>
      <Form
        form={form}
        labelCol={{ span: 2 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item
          label="Search"
          name="keyword"
          wrapperCol={{ span: 5 }}
          extra={
            <span className={componentStyle.hinttextOrange}>
              ID, Email, Username
            </span>
          }
        >
          <Input
            type="text"
            onPressEnter={(e) => onSearch((e.target as HTMLInputElement).value)}
          />
        </Form.Item>
      </Form>
      <Divider plain orientation="left">
        Search results
      </Divider>
      {searchResult && (
        <UserListView list={searchResult} onItemClick={setUser} />
      )}
      <Divider plain orientation="left">
        Detail
      </Divider>
      {user && <UserView user={user} size="full" />}
    </React.Fragment>
  );
}
