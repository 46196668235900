import React, { useState, useCallback, useEffect } from 'react';
import { Tag, Input, Row, Col, Popconfirm, Button } from 'antd';
import {
  ClearOutlined,
  CloseCircleFilled,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import componentStyle from 'styles/common.module.css';
import { DisabledType } from 'antd/lib/config-provider/DisabledContext';

export interface TagEntriesProps {
  value?: string[];
  layout: 'horizontal' | 'vertical';
  onChange?: (value: string[]) => void;
  disabled?: DisabledType;
}

export function TagEntries(props: TagEntriesProps) {
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<string[]>(props.value ?? []);

  const triggerChange = useCallback(
    (changedValue: string[]) => {
      props.onChange?.(changedValue);
    },
    [props]
  );

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const tags: string[] = inputValue
        .split(',')
        .map((t) => t.trim().toLowerCase());
      const update = [...value];
      tags.forEach((tag) => {
        if (tag && tag.length > 0 && !value.includes(tag)) {
          update.push(tag);
        }
      });
      setValue(update);
      setInputValue('');
      triggerChange(update);
    },
    [inputValue, value, triggerChange]
  );

  const handleRemoveTag = useCallback(
    (removedTag: string) => {
      const update = value.filter((tag) => tag !== removedTag);
      setValue(update);
      triggerChange(update);
    },
    [value, triggerChange]
  );

  const tagInputBox = useCallback(() => {
    return (
      <Input
        placeholder="New tag"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onPressEnter={handleEnter}
        value={inputValue}
        disabled={props.disabled}
      />
    );
  }, [handleEnter, inputValue, props.disabled]);

  const taglistView = useCallback(
    (editable: boolean) => {
      const dangerous = ['_adult', '_spoof', '_medical', '_violence', '_racy'];
      return value.map((tag, index) => (
        <Tag
          key={tag}
          closable={editable}
          onClose={() => handleRemoveTag(tag)}
          closeIcon={<CloseCircleFilled />}
          style={{ marginBottom: '0.5rem' }}
          color={dangerous.includes(tag) ? 'red' : 'default'}
          icon={dangerous.includes(tag) ? <ExclamationCircleOutlined /> : null}
        >
          {tag}
        </Tag>
      ));
    },
    [handleRemoveTag, value]
  );

  const tagRemoveAll = useCallback(() => {
    return (
      <Popconfirm
        title={'Are you sure to DELETE?'}
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        onConfirm={() => {
          setValue([]);
          triggerChange([]);
        }}
      >
        <Button type="primary" danger>
          <ClearOutlined />
        </Button>
      </Popconfirm>
    );
  }, [triggerChange]);

  const renderLayout = useCallback(
    (layout: string) => {
      switch (layout) {
        case 'horizontal':
          return (
            <Row gutter={32} align="middle">
              {!props.disabled && <Col>{tagInputBox()}</Col>}
              <Col>{taglistView(!props.disabled)}</Col>
              {!props.disabled && value.length > 0 && (
                <Col>{tagRemoveAll()}</Col>
              )}
            </Row>
          );
        case 'vertical':
          return (
            <Col>
              {!props.disabled && (
                <Row className={componentStyle.rowBottomMargin}>
                  {tagInputBox()}
                </Row>
              )}
              <Row className={componentStyle.rowBottomMargin}>
                {taglistView(!props.disabled)}
              </Row>
              {!props.disabled && value.length > 0 && (
                <Row justify="end">{tagRemoveAll()}</Row>
              )}
            </Col>
          );
        default:
          break;
      }
    },
    [props.disabled, tagInputBox, tagRemoveAll, taglistView, value.length]
  );

  return <React.Fragment>{renderLayout(props.layout)}</React.Fragment>;
}
