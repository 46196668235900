import {
  CameraConfiguration,
  CameraConfiguration_CameraRatio,
  CameraConfiguration_DeviceType,
  CameraConfiguration_Flash,
  CameraConfiguration_Position,
  Preset,
} from 'types/proto/retriver-struct_pb';
import { ImageComparisonView } from '../../../components/Thumbnail/ImageComparisonView';
import { FallbackImage } from 'utils/constants';
import {
  Button,
  Descriptions,
  Divider,
  Popconfirm,
  Typography,
  Watermark,
} from 'antd';
import viewStyle from 'styles/view.module.css';
import {
  CameraOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { proto3 } from '@bufbuild/protobuf';
import { ImageProcessingView } from './components/ImageProcessingView';
import RawJson from 'utils/RawJson';
import { IdPopover } from 'components/IdPopover';
import { Retriver } from 'api/retriver/retriver';
import { useEffect, useState } from 'react';
import { ErrorCode } from 'types/proto/retriver-enum_pb';
import {
  NewDeletePresetRequest,
  NewHidePresetRequest,
} from 'api/retriver/requestFactory/presets';
import { getMediaResolution } from 'utils/media';

export interface PresetViewProps {
  preset: Preset;
  onDelete: (id: string) => void;
}

export function PresetView(props: PresetViewProps) {
  const [preset, setPreset] = useState<Preset>(props.preset);
  const [toggleHiddenLoading, setToggleHiddenLoading] =
    useState<boolean>(false);
  const [deletePresetLoading, setDeletePresetLoading] =
    useState<boolean>(false);
  const imgWidth = 300;
  const boxId = `box-${preset.id}`;

  const toggleHidden = async () => {
    setToggleHiddenLoading(true);
    const result = (
      await Retriver.do(
        NewHidePresetRequest(preset.id, !preset.metadata?.hidden)
      )
    )?.hidePresetResponse?.preset;
    setToggleHiddenLoading(false);
    if (result) {
      setPreset(result);
    }
  };

  const deletePreset = async () => {
    setDeletePresetLoading(true);
    const result = (await Retriver.do(NewDeletePresetRequest(preset.id)))
      ?.deletePresetResponse;
    if (result && result.errorCode === ErrorCode.SUCCESS) {
      props.onDelete(preset.id);
    }
    setDeletePresetLoading(false);
  };

  useEffect(() => {
    async function adjustBoxHeight() {
      if (preset.metadata?.originUrl) {
        const imgSize = await getMediaResolution(preset.metadata?.originUrl);
        if (imgSize.height > imgSize.width) {
          const height = (imgSize.height * imgWidth) / imgSize.width;
          const box = document.getElementById(boxId);
          if (box) {
            box.style.height = `${height + 40}px`;
          }
        }
      }
    }
    adjustBoxHeight();
  });

  const renderTitlebar = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
          marginBottom: '0.6rem',
          width: '100%',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
          {preset.metadata?.title}
        </div>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <Button
            danger={!preset.metadata?.hidden}
            onClick={toggleHidden}
            loading={toggleHiddenLoading}
          >
            {preset.metadata?.hidden ? (
              <PlusCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            )}
          </Button>
          <Popconfirm
            title="Are you sure to DELETE？"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={deletePreset}
          >
            <Button
              style={{ marginLeft: '0.5rem' }}
              type="primary"
              danger
              loading={deletePresetLoading}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <>
        <div id={boxId} style={{ display: 'flex' }}>
          <ImageComparisonView
            id={preset.id}
            width={imgWidth}
            src={preset.metadata?.originUrl ?? FallbackImage}
            srcOverlay={preset.metadata?.previewUrl ?? FallbackImage}
          />
          <div
            style={{
              position: 'relative',
              left: '340px',
              paddingRight: '340px',
            }}
          >
            <MetadataView preset={preset} />
            <CameraConfigurationView conf={preset.cameraConfig} />
          </div>
        </div>
        <div>
          <ImageProcessingView processing={preset.imageProcessing} />
        </div>
      </>
    );
  };

  return (
    <div className={`${viewStyle.viewbox}`} style={{ margin: '1rem 0' }}>
      {renderTitlebar()}
      {preset.metadata?.hidden ? (
        <Watermark content={'❌ HIDDEN ❌'}>{renderDetails()}</Watermark>
      ) : (
        renderDetails()
      )}
    </div>
  );
}

function MetadataView(props: { preset: Preset }) {
  const meta = props.preset.metadata;
  return (
    <Descriptions column={2}>
      <Descriptions.Item label="ID">
        <IdPopover id={props.preset.id} content={RawJson(props.preset)} />{' '}
      </Descriptions.Item>
      <Descriptions.Item label="Description">
        {meta?.description}
      </Descriptions.Item>
      <Descriptions.Item label="Sample Color">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: `#${meta?.sampleColor}`,
              borderRadius: '50%',
              marginRight: '0.5rem',
            }}
          />
          {`#${meta?.sampleColor}`}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Author">
        <Typography.Paragraph code>
          {`${meta?.author?.id}`}
        </Typography.Paragraph>
        {`(${meta?.author?.username} / ${meta?.author?.fullname})`}
      </Descriptions.Item>
    </Descriptions>
  );
}

function CameraConfigurationView(props: {
  conf: CameraConfiguration | undefined;
}) {
  const conf = props.conf;
  const flashEnumType = proto3.getEnumType(CameraConfiguration_Flash);
  const deviceEnumType = proto3.getEnumType(CameraConfiguration_DeviceType);
  const posEnumType = proto3.getEnumType(CameraConfiguration_Position);
  const ratioEnumType = proto3.getEnumType(CameraConfiguration_CameraRatio);

  return (
    <div style={{ marginTop: '-1rem' }}>
      <Divider orientation="left">
        <CameraOutlined style={{ marginRight: '0.4rem' }} />
        Camera Configuration
      </Divider>
      <Descriptions column={3}>
        <Descriptions.Item label="WB">
          {`Temp ${conf?.whiteBalance?.temperature ?? '-'}`}
          <br />
          {`Tint ${conf?.whiteBalance?.tint ?? '-'}`}
        </Descriptions.Item>
        <Descriptions.Item label="EXP">
          {`Bias ${conf?.exposure?.bias ?? '-'}`}
          <br />
          {`ISO ${conf?.exposure?.iso ?? '-'}`}
          <br />
          {`S/S ${conf?.exposure?.shutterSpeed.toFixed(5) ?? '-'}`}
        </Descriptions.Item>
        <Descriptions.Item label="Flash">
          {`${conf?.flash ?? 0} - ${
            flashEnumType.findNumber(conf?.flash ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Device Type">
          {`${conf?.deviceType ?? 0} - ${
            deviceEnumType.findNumber(conf?.deviceType ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          {`${conf?.position ?? 0} - ${
            posEnumType.findNumber(conf?.position ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Format">{conf?.format}</Descriptions.Item>
        <Descriptions.Item label="Camera Ratio">
          {`${conf?.cameraRatio ?? 0} - ${
            ratioEnumType.findNumber(conf?.cameraRatio ?? 0)?.name
          }`}
        </Descriptions.Item>
        <Descriptions.Item label="Zoom">{`${conf?.zoom}`}</Descriptions.Item>
      </Descriptions>
    </div>
  );
}
