import { DownloadOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import Link from 'antd/es/typography/Link';
import { IdPopover } from 'components/IdPopover';
import { ImageComparisonView } from 'components/Thumbnail/ImageComparisonView';
import { useEffect } from 'react';
import { LookUpTable } from 'types/proto/retriver-struct_pb';
import { getMediaResolution } from 'utils/media';
import RawJson from 'utils/RawJson';

export interface LookUpTableViewProps {
  lookuptable: LookUpTable;
  onDelete?: (key: React.Key) => void;
}

export function LookUpTableView(props: LookUpTableViewProps) {
  const lut = props.lookuptable;
  const boxId = `box-${lut.id}`;
  const imgWidth = 300;

  useEffect(() => {
    async function adjustBoxHeight() {
      const imgSize = await getMediaResolution(lut.originUrl);
      const height = (imgSize.height * imgWidth) / imgSize.width;
      const box = document.getElementById(boxId);
      if (box) {
        box.style.height = `${height + 40}px`;
      }
    }
    adjustBoxHeight();
  });

  return (
    <div id={boxId}>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '1rem',
          marginBottom: '0.6rem',
        }}
      >
        {lut.name}
      </div>
      <div style={{ display: 'flex' }}>
        <ImageComparisonView
          id={lut.id}
          width={imgWidth}
          src={lut.originUrl}
          srcOverlay={lut.previewUrl}
        />
      </div>
      <div
        style={{ position: 'relative', left: '340px', paddingRight: '340px' }}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="ID">
            <IdPopover id={lut.id} content={RawJson(lut)} />
          </Descriptions.Item>
          <Descriptions.Item label="File URL">{lut.lutUrl}</Descriptions.Item>
          <Descriptions.Item>
            <Link href={lut.lutUrl} target="_blank">
              <DownloadOutlined />
            </Link>
          </Descriptions.Item>
        </Descriptions>
      </div>
      {/* <div>{RawJson(props.lookuptable)}</div> */}
    </div>
  );
}
